import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Storage } from '@ionic/storage';
import Chart from 'chart.js';
import { ConstantsService } from '../../service/constant.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class BrainService {

  constructor(
    public storage: Storage,
    private constants: ConstantsService
  ) { }
  
  audit_composition(job:string='',composition:string=''){
    if(_.isEmpty(job) && _.isEmpty(composition)){
      // create composition
    }
    else{
      // update composition
      let xj = _.find(
                _.get(this.constants.GLOBAL, this.constants.strings.audit.list, []),
                { id: _.get(job, 'id') }
              );
      if(!_.isUndefined(xj)){
        let p = [];
        if(composition === 'verified'){
          p = _.get(this.constants.GLOBAL, this.constants.strings.audit.verified, []);
          p.push(job);
          _.set(this.constants.GLOBAL, this.constants.strings.audit.verified, p);
          // Remove from list
          let cj = _.get(this.constants.GLOBAL, this.constants.strings.audit.list, []);
          _.remove(cj, (j)=>{
            return _.get(j, 'id', '') == _.get(job, 'id');
          });
        }
        else{
          // Audit Pending Verification created
          p = _.get(this.constants.GLOBAL, this.constants.strings.audit.list, []);
        }
      }
    }
  }
  todays_job_composition(job_id:string='',composition:string='',crew_id:string=''){
    if(_.isEmpty(job_id) && _.isEmpty(composition)){
      // create composition
    }
    else{
      // update composition
      let xj = _.find(
                _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.today', []),
                { id: job_id }
              );
      if(!_.isUndefined(xj)){
        // check if job is today
        // Add to job composition
        let p = _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.' + composition, []);
        p.push(job_id);
        // Remove from old crew composition
        let cj = _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.crew.' + crew_id, []);
        _.each(cj, (jobs,cmp)=>{
          if(cmp!=composition){
            _.remove(jobs, (j)=>{
              return _.get(j, 'id', '') == job_id;
            });
          }
        });
        // add to crew composition
        _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.crew.' + crew_id + '.' + composition, []).push(xj);
        // Update job status
        _.set(xj, 'status', _.startCase(composition));
      }
    }
  }
  async pending_audit_bi(audits){
    console.log(3);
    _.each(audits, (a,k)=>{
      console.log(4.1);

      let bi = {};
      let d = [];
      // Time at job
      let duration = moment.duration(Math.floor( _.parseInt(_.get(a,'job.tOut')) - _.parseInt(_.get(a,'job.tIn'))), 'seconds');
      if(!_.isNaN(duration.hours()) && duration.hours()>0){
        d.push(duration.hours()+"h");
      }
      if(!_.isNaN(duration.minutes()) && duration.minutes()>0){
        d.push(duration.minutes()+"m");
      }
      if(!_.isEmpty(d)){
        _.set(bi,'tAtJob',d.join(" "));
      }
      else{
        _.set(bi,'tAtJob','?');
      }
      // Time at audit
      duration = moment.duration(_.get(a,'job.tAtAudit'), 'seconds');
      d = [];
      if(!_.isNaN(duration.hours()) && duration.hours()>0){
        d.push(duration.hours()+"h");
      }
      if(!_.isNaN(duration.minutes()) && duration.minutes()>0){
        d.push(duration.minutes()+"m");
      }
      if(!_.isEmpty(d)){
        _.set(bi,'tAtAudit',d.join(" "));
      }
      else{
        _.set(bi,'tAtAudit','?');
      }
      _.set(a,'bi',bi);  
      console.log(a,bi);
      console.log(4.2);
      _.set(audits,k,a);
    });
    console.log(5);
    _.set(this.constants.GLOBAL,this.constants.strings.audit.list,audits);
  }
  audit_bi(a){
    let bi = {};
    let d = [];
    // Time at job
    let duration = moment.duration(Math.floor( _.parseInt(_.get(a,'job.tOut')) - _.parseInt(_.get(a,'job.tIn'))), 'seconds');
    if(!_.isNaN(duration.hours()) && duration.hours()>0){
      d.push(duration.hours()+"h");
    }
    if(!_.isNaN(duration.minutes()) && duration.minutes()>0){
      d.push(duration.minutes()+"m");
    }
    if(!_.isEmpty(d)){
      _.set(bi,'tAtJob',d.join(" "));
    }
    else{
      _.set(bi,'tAtJob','?');
    }
    // Time at audit
    duration = moment.duration(_.get(a,'job.tAtAudit'), 'seconds');
    d = [];
    if(!_.isNaN(duration.hours()) && duration.hours()>0){
      d.push(duration.hours()+"h");
    }
    if(!_.isNaN(duration.minutes()) && duration.minutes()>0){
      d.push(duration.minutes()+"m");
    }
    if(!_.isEmpty(d)){
      _.set(bi,'tAtAudit',d.join(" "));
    }
    else{
      _.set(bi,'tAtAudit','?');
    }
    return bi;
  }
}
