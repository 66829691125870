// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:8a9bbaa7-f26c-4035-a0cd-0e303f0db597",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_Rb3MKfkbR",
    "aws_user_pools_web_client_id": "5gsnn5ps1kdp7c32sgn9b498aj",
    "aws_user_files_s3_bucket": "tcc-convoy-uploads-tcc",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://fbueqs3dp5by5cb3qqh66cold4.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ya5cgnwyfzb6jemvzgaq56wm2u"
};


export default awsmobile;
