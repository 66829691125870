import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ConstantsService } from '../../service/constant.service';
import { API, graphqlOperation } from "aws-amplify";
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import * as deepQueries from '../../service/query/query.service';
import { BrainService } from '../brain/brain.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public storage: Storage,
    private constants: ConstantsService,
    private brainService: BrainService
  ) { }

  async list_todays_jobs() {
    let x = new Promise(async (resolve, reject) => {
      let day_start = moment().startOf('day').unix();
      let day_end = moment().endOf('day').unix();

      let all_jobs = [];
      let next = true;
      let nextToken = null;
      do {
        let jobs = await API.graphql(
          graphqlOperation(
            deepQueries.listJobs,
            {
              startTime: day_start,
              endTime: day_end,
              nextToken: nextToken
            }
          )
        );
        if (!_.isNull(_.get(jobs, 'data.listJobs.nextToken', '-'))) {
          nextToken = _.get(jobs, 'data.listJobs.nextToken', '-');
        }
        else {
          next = false;
        }
        all_jobs.push(_.get(jobs, 'data.listJobs.items', '-'));
      } while (next);
      let tjobs = this.group_jobs(_.sortBy(_.flatten(all_jobs), ['timestamp']));

      // console.log('JOBS', tjobs);

      // Storage
      this.storage.set(
        this.constants.strings.job.today,
        tjobs
      );
      // GLOBAL
      _.set(
        this.constants.GLOBAL,
        this.constants.strings.job.today,
        tjobs
      );
      resolve(true);
    });
    return x;
  }
  async list_other_jobs() {
    return await new Promise(async (resolve, reject) => {
      let st = moment().unix() - 604800;
      let et = moment().unix() + (604800 * 4);

      let all_jobs = [];
      let next = true;
      let nextToken = null;
      do {
        let jobs = await API.graphql(
          graphqlOperation(
            deepQueries.listJobs,
            {
              startTime: st,
              endTime: et,
              nextToken: nextToken
            }
          )
        );
        if (!_.isNull(_.get(jobs, 'data.listJobs.nextToken', '-'))) {
          nextToken = _.get(jobs, 'data.listJobs.nextToken', '-');
        }
        else {
          next = false;
        }
        all_jobs.push(_.get(jobs, 'data.listJobs.items', '-'));
      } while (next);
      let tjobs = this.group_jobs(_.sortBy(_.flatten(all_jobs), ['timestamp']));

      // console.log('JOBS', tjobs);

      // Storage
      this.storage.set(
        this.constants.strings.job.other,
        tjobs
      );
      // GLOBAL
      _.set(
        this.constants.GLOBAL,
        this.constants.strings.job.other,
        tjobs
      );
      resolve(true);
    });
  }
  group_jobs(jobs) {
    /**
     * Groups of jobs
     * 
     * 1. Upcoming : t > now && timestamps.auditStarted == null && timestamps.auditSubmitted == null
     * 2. Missed : t < now && timestamps.auditStarted == null && timestamps.auditSubmitted == null
     * 3. Completed: timestamps.auditStarted != null && timestamps.auditSubmitted != null
     */
    let job_groups = {
      today: [],
      missed: [],
      completed: [],
      upcoming: [],
      crew: {},
      next: {}
    };
    // Now
    let now = moment().unix();
    let day_start = moment().startOf('day').unix();
    let day_end = moment().endOf('day').unix();

    _.each(jobs, (v) => {
      v = this.format_job(v);
      let j = _.get(v, 'crew.id');
      _.set(v, 'status', 'Upcoming');
      let job_parsed_date = _.get(v, 'timestamp', moment(Date.parse(_.get(v, 'startDate'))).unix());
      if ( ( _.has(v, 'tAuditStart') && !_.isNull(_.get(v, 'tAuditStart')) ) && ( _.has(v, 'tAuditSubmit') && !_.isNull(_.get(v, 'tAuditSubmit')) ) ){
        _.set( v, 'score', parseFloat(Number(_.meanBy(_.get(v, 'tasks'), 'score') * 10).toFixed(2)) );
        _.set(v, 'status', 'Completed');
        job_groups.completed.push(v);
        _.set(job_groups.crew, j + '.completed.' + _.get(job_groups.crew, j + '.completed', []).length, v);
      }
      else {
        if (job_parsed_date > now) {
          job_groups.upcoming.push(v);
          _.set(job_groups.crew, j + '.upcoming.' + _.get(job_groups.crew, j + '.upcoming', []).length, v);
        }
        else {
          _.set(v, 'status', 'Missed');
          job_groups.missed.push(v);
          _.set(job_groups.crew, j + '.missed.' + _.get(job_groups.crew, j + '.missed', []).length, v);
        }
      }
      /**
       * If Today00 < job_parsed_date < Today24
       * Job is today
       */
      if (moment(job_parsed_date).isBetween(day_start, day_end)) {
        job_groups.today.push(v);
        /**
         * Next Job
         *  1.  Job is today
         *  2.  Tjob > now
         *  3.  Audit is not performed
         *  4.  Next job is not set yet
         */
        if ( job_parsed_date>now && _.isNull(_.get(v, 'tAuditStart', null) && _.isNull(_.get(v, 'tAuditSubmit', null))) && _.isEmpty(job_groups.next) ){
          job_groups.next = v;
        }
      }
    });
    /**
     * If no next job is set, then next job is upcoming job (1)
     * Happens when the day's jobs are over
     */
    if (_.isEmpty(job_groups.next)) {
      if(!_.isEmpty(job_groups.upcoming)){
        job_groups.next = _.get(job_groups.upcoming, '0', {});
      }
      else if(!_.isEmpty(job_groups.missed)){
        job_groups.next = _.get(job_groups.next, '0', {});
      }
    }
    return job_groups;
  }
  format_job(job) {
    let st = Date.parse(_.get(job, 'startDate'));
    let et = Date.parse(_.get(job, 'endDate'));
    let stts = moment(st).unix();
    let etts = moment(et).unix();
    _.set(
      job,
      'arrival',
      stts
    )
    _.set(
      job,
      'completion',
      etts
    )
    _.set(
      job,
      'duration',
      Math.floor((etts - stts) / 60)
    )
    _.set(
      job,
      'timeString',
      moment(st).format(this.constants.strings.moment.timeStringFormat).replace(":00", "")
    )
    _.set(
      job,
      'dateString',
      moment(st).format(this.constants.strings.moment.dateStringFormat)
    )
    _.set(
      job,
      'endTimeString',
      moment(et).format(this.constants.strings.moment.timeStringFormat).replace(":00", "")
    )
    _.set(
      job,
      'endDateString',
      moment(et).format(this.constants.strings.moment.dateStringFormat)
    )

    return job;
  }
  async list_audit(nextToken = null, paginate = false) {
    return await new Promise(async (resolve)=>{
      let all_jobs = [];
      let jobs = await API.graphql(
        graphqlOperation(
          deepQueries.listAudits,
          {
            nextToken: nextToken
          }
        )
      );
      nextToken = _.get(jobs, 'data.listPendingAuditVerifications.nextToken', '-');
      all_jobs.push(_.get(jobs, 'data.listPendingAuditVerifications.items', '-'));
      let audits = _.sortBy(_.flatten(all_jobs), ['timestamp']);
      let a = {};
      _.each(audits, (g)=>{
        _.set(g,'bi', this.brainService.audit_bi(g));
        a[_.kebabCase(g.id)]=g;
      });
      // // Storage
      // this.storage.set(
      //   this.constants.strings.audit.list,
      //   a
      // );
      // this.storage.set(
      //   'audit_next_token',
      //   nextToken
      // );
      // GLOBAL
      let b;
      if(paginate){
        b = _.merge(_.get(this.constants.GLOBAL, this.constants.strings.audit.list, []), a);
      }
      else{
        b = a;
      }
      _.set(
        this.constants.GLOBAL,
        this.constants.strings.audit.list,
        b
      );
      _.set(
        this.constants.GLOBAL,
        'audit_next_token',
        nextToken
      );
      resolve(b);
    })
  }
  async list_verified_audit(nextToken = null) {
    return await new Promise(async (resolve)=>{
      let all_jobs = [];
      let jobs = await API.graphql(
        graphqlOperation(
          deepQueries.listVerifiedAudits,
          {
            nextToken: nextToken
          }
        )
      );
      nextToken = _.get(jobs, 'data.listAuditVerifieds.nextToken', null);
      all_jobs.push(_.get(jobs, 'data.listAuditVerifieds.items', '-'));
      let audits = _.sortBy(_.flatten(all_jobs), ['timestamp']);
      let a = {};
      _.each(audits, (g)=>{
        a[_.kebabCase(g.id)]=g;
      });
      // Storage
      // this.storage.set(
      //   this.constants.strings.audit.verified,
      //   audits
      // );
      // GLOBAL
      _.set(
        this.constants.GLOBAL,      
        this.constants.strings.audit.verified,
        _.merge(_.get(this.constants.GLOBAL, this.constants.strings.audit.verified, {}), a)      
      );
      resolve(true);
    });    
  }
  async list_crew() {
    return await new Promise(async (resolve, reject) => {
      let crew = await API.graphql(
        graphqlOperation(
          deepQueries.listCrew,
        )
      );
      let crews = _.sortBy(_.get(crew, 'data.listCrews.items', []), ['name']);

      // Saving previous markers
      _.each(crews, (crew, k)=>{
        let c = _.find(_.get(this.constants.GLOBAL,this.constants.strings.crew.list), {id: crew.id});
        if(!_.isUndefined(c) && !_.isUndefined(_.get(c, 'markers'))){
          _.set(crew, 'markers', _.get(c, 'markers'));
        }
        else{
          _.set(crew,'markers', []);
        }
        let bi = {};
        let duration = moment.duration(Math.floor(_.get(crew,'bi.average_tAtJob',0)), 'seconds');
        let d = [];
        // Time at job
        if(!_.isNaN(duration.hours()) && duration.hours()>0){
          d.push(duration.hours()+"h");
        }
        if(!_.isNaN(duration.minutes()) && duration.minutes()>0){
          d.push(duration.minutes()+"m");
        }
        if(!_.isEmpty(d)){
          _.set(bi,'tAtJob',d.join(" "));
        }
        else{
          _.set(bi,'tAtJob','?');
        }
        // Time at audit
        let duration_2 = moment.duration(Math.floor(_.get(crew,'bi.average_tAtAudit',0)), 'seconds');
        let dd = [];
        if(!_.isNaN(duration_2.hours()) && duration_2.hours()>0){
          dd.push(duration_2.hours()+"h");
        }
        if(!_.isNaN(duration_2.minutes()) && duration_2.minutes()>0){
          dd.push(duration_2.minutes()+"m");
        }
        if(!_.isEmpty(dd)){
          _.set(bi,'tAtAudit',dd.join(" "));
        }
        else{
          _.set(bi,'tAtAudit','?');
        }
        _.set(crews,k+".bistrings",bi);
      });
      // Storage
      this.storage.set(
        this.constants.strings.crew.list,
        crews
      );
      // GLOBAL
      _.set(
        this.constants.GLOBAL,
        this.constants.strings.crew.list,
        crews
      );

      resolve(true);
    })
  }
  async list_clients(){    
    return await new Promise(async (resolve, reject)=>{
      let all_clients = [];
      let next = true;
      let nextToken = null;
      do{
        let clients = await API.graphql(
          graphqlOperation(
            deepQueries.listClients,
            {
              nextToken: nextToken
            }
          )
        );
        if(!_.isNull(_.get(clients, 'data.listClients.nextToken', '-'))){
          nextToken = _.get(clients, 'data.listClients.nextToken', '-');
        }
        else{
          next = false;
        }      
        all_clients.push(_.get(clients, 'data.listClients.items', '-'));
      }while( next );
      // Store
      let clts = _.sortBy(_.flatten(all_clients), ['name']);
      // Comments
      let client_notif = false;
        _.each(clts, (c,k)=>{
          if(c.comment.items.length > 0){
            _.each(c.comment.items, (ck,kc)=>{
              if(!ck.comment.includes(';ack')){
                console.log(c.name, ck.comment);
                client_notif = true;
                _.set(clts,k+'.notif',true);
              }
              else{
                if(!_.has(clts,k+'.notif')){
                  _.set(clts,k+'.notif',false);
                }
              }
            })
          }
          else{
            _.set(clts,k+'.notif',false);
          }
        })
        _.set(this.constants.GLOBAL,'client_notif',client_notif);

      this.storage.set(this.constants.strings.client.list,clts);
      _.set(this.constants.GLOBAL,this.constants.strings.client.list,clts);
      resolve(true);
    });
  }
  async crew_detail(id){
    return await new Promise(async (resolve, reject) => {
      var details = await API.graphql(
        graphqlOperation(
          deepQueries.getCrew,
          { id: id, startTime: moment().unix() - (604800), endTime: moment().unix() + (604800 * 4) }
        )
      );
      details = _.get(details, 'data.getCrew');
      // Jobs
      let jobs = _.get(details, 'job.items');
      // Splitting jobs into past and upcoming
      let job_groups = this.group_jobs(jobs);
      // Upcoming jobs
      let toSet = _.get(job_groups, 'upcoming');
      _.set( details, 'job.upcoming', toSet );
      // Missed jobs
      toSet = _.get(job_groups, 'missed');
      _.set( details, 'job.missed', toSet )
      // Completed jobs
      toSet = _.get(job_groups, 'completed');
      _.set( details, 'job.completed', toSet );
      _.unset(details, 'job.items');
      _.set( this.constants.GLOBAL, this.constants.strings.crew.detail, details );
      resolve(details);
    });
  }
  async client_detail(id: string) {
    return await new Promise(async (resolve, reject) => {
      var details = await API.graphql(
        graphqlOperation(
          deepQueries.getClient,
          { id: id, startTime: moment().unix() - (604800), endTime: moment().unix() + (604800 * 4) }
        )
      );
      details = _.get(details, 'data.getClient');
      // Jobs
      let jobs = _.get(details, 'job.items');
      // Splitting jobs into past and upcoming
      let job_groups = this.group_jobs(jobs);
      // Upcoming jobs
      let toSet = _.get(job_groups, 'upcoming');
      _.set( details, 'job.upcoming', toSet );
      // Missed jobs
      toSet = _.get(job_groups, 'missed');
      _.set( details, 'job.missed', toSet )
      // Completed jobs
      toSet = _.get(job_groups, 'completed');
      _.set( details, 'job.completed', toSet );
      _.unset(details, 'job.items');
      // Comments
      if(_.get(details,'comment.items',[]).length > 0){
        _.each(_.get(details,'comment.items'), (cd, ck)=>{
          if(cd.comment.includes(";ack")){
            _.set(details, 'comment.items.'+ck+'.comment', cd.comment.replace(';ack',''));
            _.set(details, 'comment.items.'+ck+'.status', true);
          }
          else{
            _.set(details, 'comment.items.'+ck+'.status', false);
          }
        })
      }
      _.set( this.constants.GLOBAL, this.constants.strings.client.detail, details );
      this.format_ec();
      resolve(details);
    });
  }
  format_ec(){
    let d = _.get(this.constants.GLOBAL,this.constants.strings.client.detail+".emergency");
    let contact = [];
    _.each(d.contact, (v)=>{
      if(!_.isEmpty(v.phone.replace('-',''))){
        contact.push(d);
      }
    });
    let e = {
      contact: contact,
      assembly_point: '',
      exit_doors: '',
      first_aid_kit: '',
      site_plan: ''
    }
    e.contact = contact;
    if(!_.isEmpty(d.assembly_point.replace('-',''))){
      e.assembly_point = d.assembly_point;
    }
    if(!_.isEmpty(d.exit_doors.replace('-',''))){
      e.exit_doors = d.exit_doors;
    }
    if(!_.isEmpty(d.first_aid_kit.replace('-',''))){
      e.first_aid_kit = d.first_aid_kit;
    }
    if(!_.isEmpty(d.site_plan.replace('-',''))){
      e.site_plan = d.site_plan;
    }
    _.get(this.constants.GLOBAL,this.constants.strings.client.detail+".emergency",e);
  }
  async job_detail(id: string) {
    return await new Promise(async (resolve, reject) => {
      var details = await API.graphql(
        graphqlOperation(
          deepQueries.getJob,
          { id: id }
        )
      );
      details = _.get(details, 'data.getJob');
      console.log(details);
      // Audit performed metrics
      if(!_.isNull(_.get(details,'tAuditSubmit',null))){
        // Calculate audit score
        let score = 0;
        _.each(_.get(details,'tasks'),(t)=>{
          let s = t.score.split(':');
          if(!_.isNaN(s[0])){
            score += Number(s[0]);
            _.set(t,'scores',s[1].split('-'));
            _.set(t,'comments',t.comment.split(';'));
            _.set(t,'agg_score',parseFloat(s[0]).toFixed(2));
          }
        });
        let bi = {};
        let d = [];
        // Time at job
        let duration = moment.duration(Math.floor( _.parseInt(_.get(details,'tOut')) - _.parseInt(_.get(details,'tIn'))), 'seconds');
        if(!_.isNaN(duration.hours()) && duration.hours()>0){
          d.push(duration.hours()+"h");
        }
        if(!_.isNaN(duration.minutes()) && duration.minutes()>0){
          d.push(duration.minutes()+"m");
        }
        if(!_.isEmpty(d)){
          _.set(bi,'tAtJob',d.join(" "));
        }
        else{
          _.set(bi,'tAtJob','?');
        }
        // Time at audit
        duration = moment.duration(_.get(details,'tAtAudit'), 'seconds');
        d = [];
        if(!_.isNaN(duration.hours()) && duration.hours()>0){
          d.push(duration.hours()+"h");
        }
        if(!_.isNaN(duration.minutes()) && duration.minutes()>0){
          d.push(duration.minutes()+"m");
        }
        if(!_.isEmpty(d)){
          _.set(bi,'tAtAudit',d.join(" "));
        }
        else{
          _.set(bi,'tAtAudit','?');
        }
        _.set(details,'bi',bi);  
        
        _.set( details, 'score', parseFloat((score*10/_.get(details,'tasks').length).toString()).toFixed(2) );
      }
      
      
      if(_.get(details, 'score', 0) > 0){
        // Completed and Audited        
        _.set(details,'status','completed & it\'s audit has been verified.');
      }
      else if(( _.has(details, 'tAuditStart') && !_.isNull(_.get(details, 'tAuditStart')) ) && ( _.has(details, 'tAuditSubmit') && !_.isNull(_.get(details, 'tAuditSubmit')))){
        _.set(details,'status','completed & it\'s audit is pending verification.');
      }
      else if (_.get(details,'timestamp') > moment().unix()) {
        _.set(details,'status','upcoming.');
      }
      else {
        _.set(details, 'status', 'missed.');
      }
      _.set( this.constants.GLOBAL, this.constants.strings.job.detail, details );
      resolve(true);
    });
  }
  audit_detail(id){
    return new Promise((resolve, reject)=>{
      let details = _.get(this.constants.GLOBAL, this.constants.strings.audit.list+'.'+id+'.job');
      _.set(details, 'crew', _.get(this.constants.GLOBAL, this.constants.strings.audit.list+'.'+id+'.crew'))
      let parts = _.get(details,'id').split('_');
      let image_string = "https://tcc-convoy-uploads-tcc.s3-ap-southeast-2.amazonaws.com/public/"+parts[0]+"/"+parts[2];
      _.set(details,'img',image_string);
      // Storage
      this.storage.set(
        this.constants.strings.audit.detail,
        details
      );
      // GLOBAL
      _.set(
        this.constants.GLOBAL,
        this.constants.strings.audit.detail,
        details
      );
      resolve(true);
    });
  }
  async store_client(client) {
    return await new Promise(async (resolve)=>{
      await API.graphql(
        graphqlOperation(
          deepQueries.createClient,
          { input: client }
        )
      );
      resolve(true);
    });
  }
  async store_crew(crew) {
    return await new Promise(async (resolve, reject)=>{
      await API.graphql(
        graphqlOperation(
          deepQueries.createCrew,
          { input: crew }
        )
      );
      resolve(true);
    });
  }
  async store_inventory(inventory) {
    return await new Promise(async (resolve, reject)=>{
      await API.graphql(
        graphqlOperation(
          deepQueries.createInventory,
          { input: inventory }
        )
      );
      resolve(true);
    });
  }
  async store_audit(audit, id, verifiedAudit, updatedCrew){
    return await new Promise(async (resolve)=>{
      let a = await API.graphql(
        graphqlOperation(
          deepQueries.verifyAudit,
          {
            updateJob: audit,
            updateCrew: updatedCrew,
            deleteId: { id: id },
            createAuditVerified: verifiedAudit
          }
        )
      );
      resolve(a);
    });
  }
  async update_client(client){
    return await new Promise(async (resolve)=>{
      let g = await API.graphql(
        graphqlOperation(
          deepQueries.updateClient,
          { input: client }
        )
      );
      resolve(g);
    });
  }
  async update_job(job){
    return await new Promise(async (resolve)=>{
      let g = await API.graphql(
        graphqlOperation(
          deepQueries.updateJob,
          { updateJob: job }
        )
      );
      resolve(g);
    });
  }
  async enable_disable_client(client, status){
    return await new Promise((resolve, reject)=>{
      if(status == 'disable'){
        status = 'enable';
      }
      else{
        status = 'disable';
      }
      console.log({id: client, push: {registrationType: status}});
      this.update_client({id: client, push: {registrationType: status}})
      .then(()=>{
        resolve(true);
      })
      .catch((e)=>{
        console.log(e);
      });
    })
  }
  async delete_job(job){
    return await new Promise(async (resolve)=>{
      let g = await API.graphql(
        graphqlOperation(
          deepQueries.deleteJob,
          {input: {id: job}}
        )
      );
      resolve(g);
    });
  }
  async delete_assignment(assignment){
    return await new Promise(async (resolve)=>{
      let g = await API.graphql(
        graphqlOperation(
          deepQueries.deleteAssignment,
          {input: {id: assignment}}
        )
      );
      resolve(g);
    });
  }
  async delete_client(client){
    console.log({id: client});
    return await new Promise(async (resolve)=>{
      let g = await API.graphql(
        graphqlOperation(
          deepQueries.deleteClient,
          {input: {id: client}}
        )
      );
      resolve(g);
    });
  }
  async update_crew(crew){
    return await new Promise(async (resolve)=>{
      let g = await API.graphql(
        graphqlOperation(
          deepQueries.updateCrew,
          { input: crew }
        )
      );
      resolve(g);
    });
  }
  async delete_crew(crew){
    return await new Promise(async (resolve)=>{
      let g = await API.graphql(
        graphqlOperation(
          deepQueries.deleteCrew,
          {input: {id: crew}}
        )
      );
      resolve(g);
    });
  }
  async list_audited_jobs(id) {
    return await new Promise(async (resolve, reject) => {
      let all_jobs = [];
      let next = true;
      let nextToken = null;
      do {
        let jobs = await API.graphql(
          graphqlOperation(
            deepQueries.auditedJobs,
            {
              id: id,
              nextToken: nextToken
            }
          )
        );
        if (!_.isNull(_.get(jobs, 'data.getClient.job.nextToken', '-'))) {
          nextToken = _.get(jobs, 'data.getClient.job.nextToken', '-');
        }
        else {
          next = false;
        }
        all_jobs.push(_.get(jobs, 'data.getClient.job.items', '-'));
      } while (next);
      console.log('JOBS', _.flatten(all_jobs));
      // GLOBAL
      _.set(
        this.constants.GLOBAL,
        'audited_jobs',
        all_jobs
      );
      resolve(_.flatten(all_jobs));
    });
  }
  async list_comments(){
    return await new Promise(async (resolve, reject)=>{
      let all_comments = [];
      let next = true;
      let nextToken = null;
      do{
        let clients = await API.graphql(
          graphqlOperation(
            deepQueries.listComments,
            {
              nextToken: nextToken
            }
          )
        );
        if(!_.isNull(_.get(clients, 'data.listComments.nextToken', '-'))){
          nextToken = _.get(clients, 'data.listComments.nextToken', '-');
        }
        else{
          next = false;
        }      
        all_comments.push(_.get(clients, 'data.listComments.items', '-'));
      }while( next );
      // Store
      let comments = _.orderBy(_.flatten(all_comments), ['sorter', 'name'], ['desc', 'name']);
      _.each(comments, (comment, key)=>{
        if(comment.comment.includes(';ack')){
          comments[key].comment = comment.comment.replace(';ack','');
          comments[key].read = true;
        }
        else{
          comments[key].read = false;
        }
        if(comment.comment.includes(':admin')){
          // Comments for admin only
          comments[key].for_admin = true;
          comments[key].comment = comment.comment.replace(':admin','');
        }
        else{
          comments[key].for_admin = false;
        }
      })
      console.log('Comments', comments);
      // Comments
      this.storage.set(this.constants.strings.comments.list, comments);
      _.set(this.constants.GLOBAL,this.constants.strings.comments.list, comments);
      resolve(true);
    });
  }
}
