import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: './home/home.module#HomePageModule'
  },
  { 
    path: 'location', 
    loadChildren: './pages/location/location.module#LocationPageModule' 
  },
  { 
    path: 'crew/:id', 
    loadChildren: './pages/crew/details/details.module#DetailsPageModule' 
  },
  { 
    path: 'audit', 
    loadChildren: './pages/audit/list/list.module#ListPageModule' 
  },
  { 
    path: 'audit/:id', 
    loadChildren: './pages/audit/verify/verify.module#VerifyPageModule' 
  },
  { path: 'create/client', loadChildren: './pages/create/client/client.module#ClientPageModule' },
  { path: 'create/crew', loadChildren: './pages/create/crew/crew.module#CrewPageModule' },
  { path: 'create/job', loadChildren: './pages/create/job/job.module#JobPageModule' },
  { path: 'clients', loadChildren: './pages/client/list/list.module#ListPageModule' },
  { path: 'crew', loadChildren: './pages/crew/list/list.module#ListPageModule' },
  { path: 'client/:id', loadChildren: './pages/client/details/details.module#DetailsPageModule' },
  { path: 'inventory', loadChildren: './pages/create/inventory/inventory.module#InventoryPageModule' },
  { path: 'job', loadChildren: './pages/joblist/joblist.module#JoblistPageModule' },
  { path: 'job/:id', loadChildren: './pages/job/detail/jobdetail/jobdetail.module#JobdetailPageModule' },
  { path: 'comments', loadChildren: './pages/comments/comments.module#CommentsPageModule' },
  { path: 'comment', loadChildren: './pages/comment/comment.module#CommentPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
