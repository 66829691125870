import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import * as _ from 'lodash';
import { ConstantsService } from 'src/app/service/constant.service';
import { API, graphqlOperation } from "aws-amplify";
import * as deepQueries from '../../service/query/query.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-crewopts',
  templateUrl: './crewopts.component.html',
  styleUrls: ['./crewopts.component.scss'],
})
export class CrewoptsComponent implements OnInit {
  crew;
  _: any = _;
  constructor(
    private navParams: NavParams,
    private popoverController: PopoverController,
    private http: HttpClient,
    private constant: ConstantsService
  ) { }

  ngOnInit() {
    this.crew = this.navParams.get('crew');
    console.log('From the popover', this.crew);
  }

  find_location(id: string) {
    return new Promise(async (resolve, reject) => {
      // Get crew details
      let crew = await API.graphql(
        graphqlOperation(
          deepQueries.getCrewPush,
          {
            id: id
          }
        )
      );
      let b = {
        "data": {
          "to": _.get(crew, 'data.getCrew.push.registrationId'),
          "notification": {
            "title": "Ping your location.",
            "body": "Just wondering where you are, all ok?"
          }
        },
        "options": {
          "hostname": "fcm.googleapis.com",
          "port": 443,
          "path": "/fcm/send",
          "method": "POST",
          "headers": {
            "Content-Type": "application/json"
          }
        }
      };

      this.http.post("https://wbyigf9wsb.execute-api.ap-southeast-2.amazonaws.com/prod/push/", JSON.stringify(b))
        .subscribe(data => {
          console.log(data);
          resolve(true);
        }, error => {
          console.log(error);
        });

      let e = _.find(_.get(this.constant.GLOBAL, this.constant.strings.crew.list), { id: id })
      _.set(
        e,
        'lastseen',
        null
      );
      // Dismissing popover
      this.popoverController.dismiss();
    });
  }

}
