import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { API, graphqlOperation } from "aws-amplify";
import { Router } from '@angular/router';
import { ConstantsService } from './service/constant.service';
import Chart from 'chart.js';
import Observable from 'zen-observable';
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import * as moment from 'moment';
import * as deepQueries from './service/query/query.service';
import {DataService} from './service/data/data.service';
import { BrainService } from './service/brain/brain.service';
declare var google;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  _: any = _;
  window: any = window;
  todaysJobsChart: any;
  @ViewChild('todaysJobsChartElement') todaysJobsChartElement: ElementRef;

  constructor(
    private router: Router,
    public storage: Storage,
    private constants: ConstantsService,
    public events: Events,
    private dataService: DataService,
    private brain: BrainService
  ) {
    this.initializeApp();
  }

  initializeApp() {
  }

  ngOnInit() {
    this.dataService.list_todays_jobs().then(r => {
      this.get_chart(
        _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.upcoming', []).length,
        _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.completed', []).length,
        _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.missed', []).length
      );
      this.subscribe_to_jobs();
      this.dataService.list_audit().then((audits)=>{
        this.dataService.list_verified_audit();
      });
      this.dataService.list_clients().then(()=>{
        this.subscribe_to_clients();
        this.subscribe_to_comments();
      });
      this.dataService.list_crew().then(() => {
        this.subscribe_to_crew();
        this.subscribe_to_locations();
        this.dataService.list_other_jobs();
        // Globals
        console.log('All Globals', _.get(this.constants, 'GLOBAL'));
      });
    });
  }
  get_chart(u: number, c: number, m: number) {
    this.todaysJobsChart = new Chart(this.todaysJobsChartElement.nativeElement, {
      type: 'polarArea',
      data: {
        labels: ['Upcoming', 'Missed', 'Completed'],
        datasets: [{
          backgroundColor: ['rgba(23,80,125,0.6)', 'rgba(196,0,0,0.6)', 'rgba(103,220,98,0.6)'],
          borderColor: ['rgba(23,80,125,1)', 'rgba(196,0,0,1)', 'rgba(103,220,98,1)'],
          data: [
            u,
            m,
            c
          ]
        }]
      },
      options: {
        aspectRatio: 2,
        legend: {
          display: false,
        },
        title: {
          display: false
        }
      }
    });
  }
  async subscribe_to_clients(){
    const observable: Observable<object> = API.graphql(graphqlOperation(deepQueries.subscribeClient)) as Observable<object>;
    await observable.subscribe({
      next: (value: object) => {
        _.get(this.constants.GLOBAL,this.constants.strings.client.list).push(_.get(value, 'value.data.onCreateClient'));
        if(window.location.href.includes('/client/')){
          window.location.reload();
        }
      },
      error: (error: any) => {
        console.log(error);
        this.subscribe_to_clients();
      }
    });
    console.log('Subscription to clients successful...');
  }
  async subscribe_to_comments(){
    const observable: Observable<object> = API.graphql(graphqlOperation(deepQueries.subscribeComment)) as Observable<object>;
    await observable.subscribe({
      next: (value: object) => {
        console.log(value);
        if(window.location.href.includes('/client') || window.location.href.includes('/comments')){
          window.location.reload();
        }
        this.dataService.list_clients();
      },
      error: (error: any) => {
        console.log(error);
        this.subscribe_to_comments();
      }
    });
    console.log('Subscription to comments successful...');
  }
  async subscribe_to_crew(){
    const observable: Observable<object> = API.graphql(graphqlOperation(deepQueries.subscribeCrew)) as Observable<object>;
    await observable.subscribe({
      next: (value: object) => {
        // Add to local client list
        _.get(this.constants.GLOBAL,this.constants.strings.crew.list).push(_.get(value, 'value.data.onCreateCrew'));
      },
      error: (error: any) => {
        console.log(error);
        /**
         * Re subscribing
         */
        this.subscribe_to_crew();
      }
    });
    console.log('Subscription to crew successful...');
  }
  async subscribe_to_jobs() {
    const observable: Observable<object> = API.graphql(graphqlOperation(deepQueries.subscribeJob)) as Observable<object>;
    await observable.subscribe({
      next: (value: object) => {
        let v = _.get(value, 'value.data.onUpdateJob', null);
        // Safety
        if(!_.isNull(v)){
          let jp = _.get(v,'id','').split('_');
          if(!_.isNull(_.get(v, 'auditScore', null))){
            // Audit verified
            // this.brain.audit_composition(v,'verified');
          }
          else if(!_.isNull(_.get(v, 'tIn', null)) && _.isNull(_.get(v, 'tOut', null))){
            // Entered premise
            let xc = _.find(
              _.get(this.constants.GLOBAL, this.constants.strings.crew.list, []),
              { id: jp[1] }
            );
            let xj = _.find(
              _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.today', []),
              { id: _.get(v, 'id') }
            );
            let gg = {};
            _.set(gg,'client_name',_.get(xj, 'client.name'));
            _.set(gg,'client_id',_.get(xj, 'client.name'));
            _.set(gg,'action','entered');
            _.set(gg,'timeString',moment(new Date()).format('h:mA'));
            _.set(gg,'timestamp',moment().unix());
            if(_.isUndefined(_.get(xc, 'geofence'))){
              _.set(xc, 'geofence', [gg]);
            }
            else{
              _.get(xc,'geofence',[]).push(gg);
            }
          }
          else if(!_.isNull(_.get(v, 'tIn', null)) && !_.isNull(_.get(v, 'tAuditSubmit', null)) && _.isNull(_.get(v, 'tOut', null))){
            // Audit submitted
            this.brain.todays_job_composition(_.get(v, 'id'),'completed',jp[1]);
            this.dataService.list_audit();
            this.get_chart(
              _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.upcoming', []).length,
              _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.completed', []).length,
              _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.missed', []).length
            );
          }
          else if(!_.isNull(_.get(v, 'tIn', null)) && !_.isNull(_.get(v, 'tAuditSubmit', null)) && !_.isNull(_.get(v, 'tOut', null))){
            // Exited premise
            let xc = _.find(
              _.get(this.constants.GLOBAL, this.constants.strings.crew.list, []),
              { id: jp[1] }
            );
            let xj = _.find(
              _.get(this.constants.GLOBAL, this.constants.strings.job.today + '.today', []),
              { id: _.get(v, 'id') }
            );
            let gg = {};
            _.set(gg,'client_name',_.get(xj, 'client.name'));
            _.set(gg,'client_id',_.get(xj, 'client.id'));
            _.set(gg,'action','exited');
            _.set(gg,'timeString',moment(new Date()).format('h:mA'));
            _.set(gg,'timestamp',moment().unix());
            if(_.isUndefined(_.get(xc, 'geofence'))){
              _.set(xc, 'geofence', [gg]);
            }
            else{
              _.get(xc,'geofence',[]).push(gg);
            }
          }
        }
      },
      error: (error: any) => {
        console.log(error);
        /**
         * Re subscribing
         */
        this.subscribe_to_jobs();
      }
    });
    console.log('Subscription to jobs successful...');
  }
  async subscribe_to_locations() {
    const observable: Observable<object> = API.graphql(graphqlOperation(deepQueries.subscribeGeolocation)) as Observable<object>;
    await observable.subscribe({
      next: (value: object) => {
        // Show on map
        // TODO Not subscribing to location
        // this.save_marker(_.get(value, 'value.data.onCreateGeolocation'));
      },
      error: (error: any) => {
        console.log(error);
        /**
         * Re subscribing
         */
        this.subscribe_to_locations();
      }
    });
    console.log('Subscription to locations successful...');
  }
  save_marker(gl) {
    // Some commonly used variables
    let u = _.get(gl, 'crew.id', 'nik@blackquadrant.com');
    let lt = _.get(gl, 'location.coords.latitude');
    let lg = _.get(gl, 'location.coords.longitude');
    let ts = _.get(gl, 'timestamp');
    // For the dashboard
    this.update_crew_current_location(lt, lg, u);
    // 
    let marker = {
      position: [lt, lg],
      icon: '../assets/team/pin.' + u + '.png',
      title: ts,
      timestamp: ts
    };
    // 
    let c = _.find(_.get(this.constants.GLOBAL, this.constants.strings.crew.list), { id: u });
    if(_.isUndefined(c)){
      console.log('Could not find crew ' + u);
    }
    else{
      _.get(c,'markers').push(marker);
    }
    // 
    this.events.publish('location:received', {loc:gl,crew:{id:c.id, name:c.name}});
  }
  async update_crew_current_location(lt:number, lg:number, u:number){
    // Updating local info
    var geocoder = new google.maps.Geocoder;
    geocoder.geocode({ 'location': { lat: lt, lng: lg } }, _.bind(function (results, status) {
      if (status === 'OK') {
        if (results[0]) {
          let b = _.find(_.get(this.constants.GLOBAL, this.constants.strings.crew.list), { id: u })
          _.set(
            b,
            'lastseen',
            results[0].formatted_address
          );
          // console.log(results);
        }
      }
    }, this));
  }
}
