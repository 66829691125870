import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  public VERSION = '0.4.2';

  public strings = {
    map:{
      map: 'map',
      markers: 'markers',
    },
    client: {
      list: 'cll',
      detail: 'cld'
    },
    comments:{
      list: 'cml'
    },
    crew: {
      list: "crl",
      detail: "crd"
    },
    job: {
      today: 'jt',
      crew: 'crew',
      other: 'jo',
      detail: 'jd'
    },
    audit: {
      list: 'al',
      detail: 'ad',
      verified: 'av'
    },
    moment: {
      format: 'dddd, MMM Do [at] h:mm A',
      timeStringFormat: 'h:mm A',
      dateStringFormat: 'dddd, MMM Do',
      rangeFormat: 'MMMM, Do'
    }
  };

  public GLOBAL = {
  };

  public colors = {
    tap: ['#1abc9c', '#f1c40f', '#e74c3c', '#8e44ad', '#2c3e50', '#c0392b', '#3498db', '#2ecc71', '#e67e22', '#f39c12'],
    sink: {}
  };

  set_env(env: string) {
  }
  
  constructor(
    private storage: Storage
  ) { }
}
