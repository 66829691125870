export const createAssignment = `mutation createAssignment($input: CreateAssignmentInput!) {
  createAssignment(input: $input) {
    crew{
      id
      name
    }
    client{
      id
      name
      jobDescription{
        startDate
        endDate
      }
    }
  }
}
`;

export const createClient = `mutation createClient($input: CreateClientInput!) {
  createClient(input: $input) {
    id
    name
    jobDescription{
      startDate
      endDate
    }
    address{
      street
      city
      latitude
      longitude
    }
  }
}
`;

export const createCrew = `mutation createCrew($input: CreateCrewInput!) {
  createCrew(input: $input) {
    id
    name
    position
    branch
    contact {
      phone
      email
    }
  }
}
`;

export const getCrewLocation = `query ListGeolocations(
  $nextToken: String,
  $startTime: Int,
  $endTime: Int
) {
  listGeolocations(filter:{
    timestamp:{
      between:[
        $startTime,
        $endTime
      ]
    }
  }, limit: 9000, nextToken: $nextToken) {
    items {
      crew{
        id
      }
      timestamp
      location{
        coords{
          latitude
          longitude
        }
      }
    }
    nextToken
  }
}
`;
export const getCrewPush = `query GetCrew($id: ID!) {
  getCrew(id: $id) {
    push{
      registrationId
      registrationType
    }
  }
}
`;

export const getCrew = `query GetCrew($id: ID!,$startTime: Int,$endTime: Int) {
  getCrew(id: $id) {
    id
    name
    bi{
      average_audit_score
      total_completed_jobs
      total_missed_jobs
      total_assigned_jobs
      average_missed_job
      average_tAtAudit
      average_tAtJob
    }
    push{
      registrationId
      registrationType
    }
    contact{
      email
      phone
    }
    branch
    client{
      items{
        client{
          id
          name
          address{
            street
            city
          }
        }
      }
    }
    job(
      filter:{
        timestamp:{
          between:[
            $startTime,
            $endTime
          ]
        }
      }
      sortDirection:ASC
      limit:9000
    ) {
      items {
        id
        timestamp
        startDate
        endDate
        tIn
        tOut
        tAuditStart
        tAuditSubmit
        tAtJob
        tAtAudit
        client{
          id
          name
          address{
            street
            city
            latitude
            longitude
          }
        }
      }      
    }
  }
}
`;

export const listCrew = `query ListCrews(
  $filter: ModelCrewFilterInput
  $nextToken: String
) {
  listCrews(filter: $filter, limit: 9000, nextToken: $nextToken) {
    items {
      id
      name
      position
      branch
      bi{
        average_audit_score
        total_completed_jobs
        total_missed_jobs
        total_assigned_jobs
        average_missed_job
        average_tAtAudit
        average_tAtJob
      }
      contact {
        phone
        email
      }
    }
    nextToken
  }
}
`;

export const createGeolocation = `mutation Geolocation($input: CreateGeolocationInput!) {
  createGeolocation(input: $input) {
    crew{
      id
    }
    timestamp
    location{
      coords{
        latitude
        longitude
      }
    }
  }
}
`;

export const createJob = `mutation CreateJob($input: CreateJobInput!) {
  createJob(input: $input) {
    id
    startDate
    endDate
    client{
      id
      name
    }
    crew{
      id
      name
    }
    timestamp
    sorter
    grouper
  }
}
`;

export const listAudits = `
query ListPendingAuditVerifications(
  $nextToken: String
) {
  listPendingAuditVerifications(
    nextToken: $nextToken
  ) {
    items {
      id
      date
      crew {
        id
        name
      }
      timestamp
      job {
        client{
          id
          name
        }
        id
        startDate
        endDate
        timestamp
        tIn
        tOut
        tAuditStart
        tAuditSubmit
        tAtJob
        tAtAudit
        tasks{
          area
          task
          score
          comment
          priority
          piccount
          text
          taskindex
        }
      }
    }
    nextToken
  }
}
`;

export const listVerifiedAudits = `
query listAuditVerifieds(
  $nextToken: String
) {
  listAuditVerifieds(
    nextToken: $nextToken
  ) {
    items {
      id
      date
      auditScore
      notifyClient
      crew {
        id
        name
      }
      timestamp
      job {
        client{
          id
          name
        }
        id
        startDate
        endDate
        timestamp
        tasks{
          area
          task
          score
          comment
          priority
          piccount
          text
          taskindex
        }
      }
    }
    nextToken
  }
}
`;

export const listJobs = `query ListJobs($nextToken: String, $startTime: Int, $endTime: Int) {
  listJobs(filter: {
    timestamp:{
          between:[
            $startTime,
            $endTime
          ]
        }
  }, limit: 9000, nextToken: $nextToken) {
    items {
      id
      startDate
      endDate
      client {
        id
        name
        image
        push{
          registrationType
        }
        address{
          street
          city
          latitude
          longitude
        }
      }
      crew {
        id
        name
      }
      timestamp
      sorter
      tIn
      tOut
      tAuditStart
      tAuditSubmit
      tAtJob
      tAtAudit
      tasks {
        area
        task
        score
        comment
        priority
        piccount
        text
        taskindex
      }
    }
    nextToken
  }
}`;

export const getNextJobOfCleaner = `query listJobs($id:ID, $limit:Int){
  listJobs(limit:$limit){
    items{
      id
      startDate
      endDate
      client{
        id
        name
        contact{
          phone
          email
        }
        address{
          street
          city
          po_box
          latitude
          longitude
        }
      }
      crew{
        id(filter:{
          eq:$id
        })
        name
        image
      }
    }
  }
}`;

export const getClient = `query getClient($id: ID!,$startTime: Int,$endTime: Int) {
  getClient(id: $id) {
    id
    name
    address{
      street
      city
      po_box
      latitude
      longitude
    }
    push{
      registrationType
    }
    image
    contact{
      phone
      email
    }
    comment(sortDirection:DESC, limit: 5){
      items{
        id
        comment
        dateTime
      }
    }
    emergency{
      contact{
        name
        phone
      }
      site_plan
      first_aid_kit
      exit_doors
      assembly_point
    }
    crew{
      items{
        crew{
          id
          name
        }
      }
    }
    jobDescription{
      startDate
      endDate
      jobTask{
        area
        task
        frequency
        priority
        text
        piccount
        score
        comment
        taskindex
      }
    }
    job(
      filter:{
        timestamp:{
          between:[
            $startTime,
            $endTime
          ]
        }
      }
      sortDirection:ASC
      limit:9000
    ) {
      items {
        id
        endDate
        startDate
        tIn
        tOut
        tAuditStart
        tAuditSubmit
        tAtJob
        tAtAudit
        tasks{
          area
          task
          score
          comment
          priority
          piccount
          text
          taskindex
        }
      }      
    }
  }
}`;

export const getJob = `query GetJob($id: ID!) {
  getJob(id: $id) {
    id
    startDate
    endDate
    timestamp
    tasks{
      area
      task
      score
      comment
      priority
      piccount
      text
      taskindex
    }
    tIn
    tOut
    tAuditStart
    tAuditSubmit
    tAtJob
    tAtAudit
    client {
      id
      name
      push{
        registrationType
      }
      address {
        street
        city
        po_box
        latitude
        longitude
      }
      image
      contact {
        phone
        email
      }
    }
    crew {
      id
      name
      contact {
        phone
        email
      }
    }
  }
}
`;

export const deleteClient = `mutation deleteClient($input: DeleteClientInput!){
  deleteClient(input: $input){
    id
  }
}`;

export const deleteAssignment = `mutation deleteAssignment($input: DeleteAssignmentInput!){
  deleteAssignment(input: $input){
    id
  }
}`;

export const deleteJob = `mutation deleteJob($input: DeleteJobInput!){
  deleteJob(
    input: $input
    condition:{
      tAtAudit:{
        attributeExists: false
      }
    }
  ){
    id
  }
}`;

export const deleteCrew = `mutation deleteCrew($input: DeleteCrewInput!){
  deleteCrew(input: $input){
    id
  }
}`;

export const verifyAudit = `mutation verifyAudit($updateJob: UpdateJobInput!, $updateCrew: UpdateCrewInput!,$deleteId: DeletePendingAuditVerificationInput!, $createAuditVerified: CreateAuditVerifiedInput!) {
  updateJob(input: $updateJob){
    id
    auditScore
    tIn
    tOut
    tAuditStart
    tAuditSubmit
    tAtJob
    tAtAudit
    client{
      id
      name
    }
    timestamp
    crew{
      id
      name
    }
  }
  updateCrew(input: $updateCrew){
    id
    bi{
      average_audit_score
      total_completed_jobs
      total_missed_jobs
      total_assigned_jobs
      average_missed_job
      average_tAtAudit
      average_tAtJob
    }
  }
  createAuditVerified(input: $createAuditVerified){
    id
  }
  deletePendingAuditVerification(input: $deleteId){
    id
  }
}
`;

export const updateJob = `mutation updateJob($updateJob: UpdateJobInput!) {
  updateJob(input: $updateJob){
    id
    startDate
    endDate
    client{
      id
      name
    }
    crew{
      id
      name
    }
    timestamp
    sorter
    grouper
  }
}
`;

export const createInventory = `mutation createAudit($input: CreateInventoryInput!) {
  createInventory(input: $input){
    id
  }
}
`;

export const updateCrew = `mutation UpdateCrew($input: UpdateCrewInput!) {
  updateCrew(input: $input) {
    id
    push{
      registrationId
      registrationType
    }
    name
  }
}
`;

export const updateClient = `mutation UpdateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    id
  }
}
`;

export const getClientsOfCrew = `query listClients($id:ID, $limit:Int) {
  listAssignments(
    filter:{
      id:{
        contains:$id
      }
    }
    limit:$limit
  ){
    items{
      id
      crew{
        id
        name
      }
      client{
        id
        name
      }
    }
  }
}
`;

export const listClientCrewAssignments = `query ListClients(
  $nextToken: String
) {
  listAssignments(nextToken: $nextToken) {
    items {
      id
      crew{
        id
        name
      }
      client{
        id
        name
        address{
          street
          city
          latitude
          longitude
        }
        jobDescription{
          startDate
          endDate
          jobTask{
            area
            task
            frequency
            priority
            text
            piccount
            taskindex
          }
        }
      }
    }
    nextToken
  }
}
`;

export const listClients = `query ListClients(
  $nextToken: String
) {
  listClients(nextToken: $nextToken) {
    items {
      id
      name
      push{
        registrationType
      }
      address{
        street
        city
        latitude
        longitude
      }
      jobDescription{
        startDate
        endDate
        jobTask{
          area
          task
          frequency
          priority
          text
          piccount
          taskindex
          comment
        }
      }
      crew{
        items{
          crew{
            id
            name
          }
        }
      }
      comment(sortDirection:DESC, limit: 5){
        items{
          id
          comment
          dateTime
        }
      }
    }
    nextToken
  }
}
`;


export const subscribeGeolocation = `subscription g{
  onCreateGeolocation{
    crew{
      id
    }
    timestamp
    location{
      coords{
        latitude
        longitude
      }
    }
  }
}`;

export const subscribeClient = `subscription cl{
  onCreateClient{
    id
    name
    address{
      street
      city
      latitude
      longitude
    }
    jobDescription{
      startDate
      endDate
      jobTask{
        area
        task
        frequency
        priority
        text
        piccount
        taskindex
        comment
      }
    }
  }
}`;
export const subscribeComment = `subscription cl{
  onCreateComment{
    id
  }
  onUpdateComment{
    id
  }
}`;
export const subscribeCrew = `subscription cr{
  onCreateCrew{
    id
    name
    position
    branch
    contact {
      phone
      email
    }
  }
}`;


export const subscribeJob = `subscription a{
  onUpdateJob{
    id
    auditScore
    tIn
    tOut
    tAuditStart
    tAuditSubmit
    tAtJob
    tAtAudit
  }
}`;

export const auditedJobs = `query getClient($id: ID!){
  getClient(id:$id){
    job(
      filter:{
        auditScore:{
          ge: "1"
        }
      }
      limit: 9000
    ){
      items{
        auditScore
        startDate
        id
        endDate
        timestamp
        tIn
        tOut
        tAuditStart
        tAuditSubmit
        tAtJob
        tAtAudit
        tasks{
          area
          task
          frequency
          priority
          text
          piccount
          imgExpected
          imgAudited
          score
          comment
        }
        client {
          id
          name
          image
          push{
            registrationType
          }
          address{
            street
            city
            latitude
            longitude
          }
        }
        crew {
          id
          name
        }
      }
      nextToken
    }    
  }
}`;

export const listComments = `
  query Comments {
    listComments(limit: 9000) {
      items {
        id
        comment
        dateTime
        client {
          name
          id
        }
        dateTime
        sorter
      }
      nextToken
    }
  }
`;